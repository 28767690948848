import React from 'react';


import { Spinner } from '../../ui/spinner/Spinner';
import { PageTitle2 } from '../../ui/page/PageTitle2';
import { useTranslation } from 'react-i18next';
import { InputLabels } from '../../ui/input/InputLabels';
import { TextSummary } from '../../../ui/input/TextSummary';
import { Box } from 'reflexbox';
import { Input } from '../../ui/input/Input';
import { YES_NO_OPTIONS, LIST_OF_STATUTS } from '../../constants/picker/options';
import { Checkboxes } from '../../ui/checkboxes/Checkboxes';
import {
    AVAILABLE_INFORMATION,
} from '../../models/interactions/withCreateInteraction';
import { UploadButtons } from '../../ui/button/UploadButtons';
import { DEFAULT_COLOR, DEFAULT_TEXT_COLOR } from '../../constants/customisation/customisation';
import { Relative } from '../../ui/box/Relative';
import { PlusMinusIconWrapper } from '../../ui/interactions/PlusMinusIconWrapper';
import { MinusIcon } from '../../ui/icons/MinusIcon';
import { PlusIcon } from '../../ui/icons/PlusIcon';
import { userLanguages } from '../../models/users/withBEUser';
import { MP4_BACKGROUND_STR, STATIC_BUCKET } from '../../constants/common'; 

export default function InteractionInformation({
    interaction,
    setField,
    customisation,
    answerCount,
    addNewChoice,
    removeChoiceOnIndex,
    isInformationToGatherDisabled,
    setIsInformationToGatherDisabled,
    disabledExceptions,
    setDisabledExceptions,
    templateShowSegment,
    saveInteraction,
    isBackgroundSaved = true,
    disabled = false,
    creation = false
}) {
    const { t } = useTranslation();
    const setFieldGDPR = (name, value) => {
        setField(name, value);
        setField('customisation|consent_checkbox', false);
        setField('is_gdpr_consent', value)
    }
    const setFieldBackground = async (name, value) => {
        await setField(name, value);
        interaction.background = value;
        interaction.background_url = value;
        await saveInteraction();
    }
    const setFieldGatherInformation = (name, value) => {
        setIsInformationToGatherDisabled(value ? '' : 'disabled');
        setDisabledExceptions([]);
        if (!value) {
            for (const [index, obj] of Object.entries(AVAILABLE_INFORMATION)) {
                console.log(`information_to_gather|${obj.key}`);
                setField(`information_to_gather|${obj.key}`, false)
            }
        }
        setField(name, value);
        console.log("-----")
    }
    const setFieldImageRight = (name, value) => {
        setField(name, value);
        setField('customisation|consent_checkbox_image_right', false);
        setField('is_image_right_consent', value);
    }


    return (
        <Box>
            {templateShowSegment && <>
                <PageTitle2>
                    {t('General informations')}
                </PageTitle2>
                <Input
                    value={interaction.title}
                    name={'title'}
                    onChange={setField}
                    label={t('Title')}
                    disabled={disabled}
                    maxLength={100}
                />
                {!creation &&
                    <Input
                        select
                        value={interaction.status}
                        name={'status'}
                        onChange={setField}
                        options={LIST_OF_STATUTS}
                        label={t('Status')}
                        disabled={disabled}
                    />
                }
                <InputLabels label={t('Direct link')}>
                    <TextSummary
                        semiBold
                        as={'a'}
                        theme={'link'}
                        href={interaction.shareUrl}
                        target={'_blank'}
                        rel={'noreferrer'}
                        disabled={disabled}
                    >
                        {interaction.shareUrl}
                    </TextSummary>
                </InputLabels>
                <Input
                    textArea
                    value={templateShowSegment.description}
                    name={'widget_model'}
                    label={t('Model')}
                    disabled={true}
                    rows={2}
                    style={{ border: '1px solid #e5e5e5', minHeight: '2em', maxHeight: '4em', overflowY: 'auto' }}
                />
                <Input
                    checkbox
                    value={interaction.limit_1_vote}
                    name={'limit_1_vote'}
                    onChange={setField}
                    options={YES_NO_OPTIONS}
                    label={t('Limit to 1 participation')}
                    disabled={disabled}
                />
                {
                    // TODO Revoir affichage de l'aide (uniquement au survol)
                }
                <Input
                    checkbox
                    value={interaction.gather_information}
                    name={'gather_information'}
                    onChange={setFieldGatherInformation}
                    options={YES_NO_OPTIONS}
                    description={t(
                        'This option allows you to collect information from the audience who sends their voice participation as part of the broadcast of their message on the air.'
                    )}
                    label={t('Collecting personal informations')}
                    disabled={customisation.with_connexion || disabled}
                />

                <Input
                    checkbox
                    value={customisation.consent_gdpr}
                    name={'customisation|consent_gdpr'}
                    onChange={setFieldGDPR}
                    options={YES_NO_OPTIONS}
                    description={''}
                    label={t('Consent GDPR')}
                    disabled={disabled}
                />
                <Input
                    checkbox
                    value={customisation.image_right}
                    name={'customisation|image_right'}
                    onChange={setFieldImageRight}
                    options={YES_NO_OPTIONS}
                    description={''}
                    label={t('Consent image right')}
                    disabled={disabled}
                />
                <Input
                    select
                    value={customisation.language}
                    label={t('Language')}
                    name={'customisation|language'}
                    onChange={setField}
                    options={userLanguages}
                    disabled={disabled}
                />
                <PageTitle2>
                    {t('Customization')}
                </PageTitle2>
                <Input
                    value={interaction.text}
                    name={'text'}
                    onChange={setField}
                    label={t('Home text')}
                    textArea
                    disabled={disabled}
                />
                {// Masquer temporairement
                interaction.webview_version === '02' && false &&
                    <Input
                        value={interaction.instructions}
                        name={'instructions'}
                        onChange={setField}
                        label={t('Instructions')}
                        disabled={disabled}
                    />
                }
                {
                    // TODO revoir style du bouton
                }
                {!isBackgroundSaved &&
                    <Spinner />
                }
                {interaction.background_url && interaction.background_url.includes(MP4_BACKGROUND_STR) && !interaction.background_url.includes(STATIC_BUCKET) ?
                (
                    <video src={interaction.background_url} autoPlay loop muted playsInline width="360px" />
                ) : (
                    <img src={interaction.background_url} width={'360px'} />
                )}
                <Input
                    label={t('Principal media')}
                    description={t('Media size 360x360, format accepted: png, jpg, mp4, webm (10Mo max)')}
                    disabled={true}
                />
                <UploadButtons
                    acceptVideo
                    onUpload={setFieldBackground}
                    text={t('Upload a custom media')}
                    name={'background'}
                    disabled={disabled}
                />
                <br />
                {templateShowSegment.show_customisation_incentive_text && (
                    <Input
                        value={interaction.testimonial}
                        name={'testimonial'}
                        onChange={setField}
                        label={t('Incentive text')}
                        textArea
                        description={t(
                            'This text makes it possible to personalise the incentive to leave a voice participation after the vote.'
                        )}
                        disabled={disabled}
                    />
                )}


                <Input
                    value={customisation.text_thanks}
                    name={'customisation|text_thanks'}
                    onChange={setField}
                    label={t('Thank-you text')}
                    disabled={disabled}
                    textArea
                />
                {!interaction.customed && interaction.webview_version === '01' &&
                    <>
                        <Input
                            value={customisation["btn_1_color"] || DEFAULT_COLOR}
                            name={"customisation|btn_1_color"}
                            onChange={setField}
                            label={"Main button color"}
                            type={'color'}
                            disabled={disabled}
                        />
                        <Input
                            value={customisation.btn_1_text_color || DEFAULT_TEXT_COLOR}
                            name={"customisation|btn_1_text_color"}
                            onChange={setField}
                            label={"Main text color"}
                            type={'color'}
                            disabled={disabled}
                        />
                    </>
                }
                {(
                    templateShowSegment.show_data_collection_multiple_choice ||
                    templateShowSegment.show_data_collection_video ||
                    templateShowSegment.show_data_collection_sound) &&
                    (
                        <PageTitle2>
                            {t('Data collection')}
                        </PageTitle2>
                    )
                }
                {templateShowSegment.show_data_collection_multiple_choice && (
                    <>
                        {Array.from({ length: answerCount }).map((values, index) => {
                            const value = interaction.choices[index];
                            const name = String.fromCharCode(65 + index);
                            return (
                                <Relative>
                                    <Input
                                        key={name}
                                        mt={index === 1 ? '-12px' : undefined}
                                        value={value || ''}
                                        onChange={setField}
                                        label={!index && t('Possible answer')}
                                        description={!index && t('2 answers minimum.')}
                                        name={`choices|${index}`}
                                        disabled={disabled}
                                    />
                                    <PlusMinusIconWrapper
                                        onClick={() => removeChoiceOnIndex(index)}
                                    >
                                        <MinusIcon />
                                    </PlusMinusIconWrapper>
                                </Relative>
                            );
                        })}
                        <Relative>
                            <Input placeholder={t('Add a new answer')} dash disabled />
                            <PlusMinusIconWrapper onClick={addNewChoice}>
                                <PlusIcon />
                            </PlusMinusIconWrapper>
                        </Relative>
                    </>
                )}
                {(templateShowSegment.show_data_collection_sound || templateShowSegment.show_data_collection_video) && (
                    <Input
                        type={'number'}
                        value={interaction.max_recording_time}
                        name={'max_recording_time'}
                        onChange={setField}
                        label={t('Recording time')}
                        disabled={disabled}
                    />
                )}
                {interaction.gather_information &&
                    <>
                        <PageTitle2>
                            {t('Personal informations collection')}
                        </PageTitle2>
                        <Input
                            value={customisation.text_personal}
                            name={'customisation|text_personal'}
                            onChange={setField}
                            label={t('Personal information text')}
                            textArea
                            disabled={disabled}
                        />

                        <Checkboxes
                            possibleValues={AVAILABLE_INFORMATION}
                            onChange={(key, value) =>
                                setField(`information_to_gather|${key}`, value)
                            }
                            value={interaction.information_to_gather}
                            disabledException={disabledExceptions}
                            disabled={isInformationToGatherDisabled || disabled}
                            doubleCheckbox
                            label={t('Data to collect')}
                        />
                    </>
                }
                {customisation.consent_gdpr && (
                    <div>
                        <PageTitle2>
                            {t('Consent GDPR IS')}
                        </PageTitle2>
                        <Input
                            value={customisation.title_consent}
                            name={'customisation|title_consent'}
                            onChange={setField}
                            label={t('Title consent')}
                            disabled={disabled}
                            description={t('If empty the default value is : Consent Personal Data')}
                        />
                        <Input
                            value={customisation.consent_gdpr_text}
                            name={'customisation|consent_gdpr_text'}
                            onChange={setField}
                            description={''}
                            label={t('Consent GDPR text')}
                            textArea
                            disabled={disabled}
                        />
                        <Input
                            checkbox
                            value={customisation.consent_checkbox}
                            name={'customisation|consent_checkbox'}
                            onChange={setField}
                            options={YES_NO_OPTIONS}
                            description={''}
                            label={t('Use checkbox validation')}
                            disabled={disabled}
                        />
                    </div>
                )}
                {customisation.image_right && (

                    <div>
                        <PageTitle2>
                            {t('Consent image right IS')}
                        </PageTitle2>
                        <Input
                            value={customisation.title_consent_image_right}
                            name={'customisation|title_consent_image_right'}
                            onChange={setField}
                            label={t('Title consent')}
                            disabled={disabled}
                            description={t('If empty the default value is : Consent Personal Data')}
                        />
                        <Input
                            value={customisation.image_right_text}
                            name={'customisation|image_right_text'}
                            onChange={setField}
                            description={''}
                            textArea
                            disabled={disabled}
                        />
                        <Input
                            checkbox
                            value={customisation.consent_checkbox_image_right}
                            name={'customisation|consent_checkbox_image_right'}
                            onChange={setField}
                            options={YES_NO_OPTIONS}
                            description={''}
                            label={t('Use checkbox validation')}
                            disabled={disabled}
                        />

                    </div>
                )}
            </>}
        </Box>
    );
}
