import React from 'react';
import { Text } from '../../ui/text/Text';
import { Heading } from '../../ui/text/Heading';
import { useTranslation } from 'react-i18next';
import {
  COLOR,
  DATE_FORMAT,
  INTERACTION_API_TYPE_MAP,
  TIME_FORMAT,
} from '../../constants/common';
import styled, { css } from 'styled-components';
import {
  AVAILABLE_INFORMATION,
  CREATE_INTERACTION_STEPS,
  GAME_TYPES,
  SURVEY_TYPES,
  MEDIA_SURVEY_TYPES,
  WithCreateInteraction,
} from '../../models/interactions/withCreateInteraction';
import { INTERACTION_FRONTEND_CATEGORY_MAP } from '../../constants/interactions/interaction';
import { Button } from '../../ui/button/Button';
import { Flex, Box } from 'reflexbox';
import { FlexCenter } from '../../ui/box/FlexCenter';
import { WidgetPreview } from '../../ui/widgetPreview/WidgetPreview';
import { useUser } from '../../../models/users/withUser'; // TODO:NEW Remove one "../"
import { useModal } from '../../models/global/withModal';
import { Footer } from '../../ui/modal/Footer';
import { Link } from 'react-router-dom';
import { Steps } from '../../../features/steps/Steps';
import { RadioPicker } from '../../ui/picker/RadioPicker';
import InteractionInformation from '../../ui/component/InteractionInformations';
import InteractionAvailability from '../../ui/component/InteractionAvailability';
import {
  Cell,
  Row,
  Table,
  TableBody,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
  TableWrapper,
} from '../../ui/table/Table';
import { templateSettings } from 'lodash';

const Wrapper = styled(Flex)`
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

const Header = styled(Flex)`
  height: 61px;
  border-bottom: 1px solid ${COLOR.veryLightPink};
  padding: 0 54px;
`;

const Container = styled(Flex)`
  flex: 1;
  max-height: calc(100% - 122px);
`;

const Content = styled(Flex)`
  flex: 1;
`;

const TypeSelectWrapper = styled.div`
  overflow-y: scroll;
  width: 28%;
  border-right: 1px solid ${COLOR.veryLightPink};
`;

const ContentWrapper = styled(Box)`
  padding: 21.5px 74px 21.5px 32px;
  flex: 1;
`;

const InteractionType = styled(Flex)`
  align-items: center;
  height: 115px;
  cursor: pointer;
  padding: 20px;
  border-bottom: 1px solid ${COLOR.veryLightPink};
  svg {
    height: 35px;
    width: 35px;
  }
  ${({ active }) =>
    active &&
    css`
      background: ${COLOR.white2};
    `}
`;

const InnerScrollWrapper = styled(Flex)`
  overflow-y: auto;
  flex: 1;
  padding: 10px 10px 10px 0;
  margin-right: 40px !important;
  flex-direction: column;
`;

const InnerMaxWidth = styled(Flex)`
  max-width: 95%;
  height: 100%;
`;

export const CreateInteraction = ({ initData }) => {
  const { t } = useTranslation();
  const { channel } = useUser();
  const { closeModal } = useModal();
  const {
    interaction,
    isStepValid,
    isSaved,
    step,
    setField,
    nextStep,
    previousStep,
    setFrom,
    showColors,
    setShowColors,
    setTo,
    answerCount,
    removeChoiceOnIndex,
    addNewChoice,
    saveInteraction,
    resetCustomisation,
    isInformationToGatherDisabled,
    setIsInformationToGatherDisabled,
    disabledExceptions,
    setDisabledExceptions,
    getTemplatesShowSegments,
    templatesShowSegments,
    templateShowSegment,
    newInteractionId,
    getTemplateShowSegment,
    isBackgroundSaved
  } = WithCreateInteraction({
    initInteraction: initData,
    channelId: channel.id,
  });
  const availableTypes = [
    INTERACTION_API_TYPE_MAP.vote,
    INTERACTION_API_TYPE_MAP.voice,
    INTERACTION_API_TYPE_MAP.video,
    INTERACTION_API_TYPE_MAP.text,
    INTERACTION_API_TYPE_MAP.game,
  ];
  const availableCategories = [
    'survey',
    'text_participation',
    'vocal_participation',
    'video_participation',
    'image_participation',
    'game',
  ];

  const { customisation } = interaction;

  const changeInteraction = async (category) => {
    setField('template_category', category)
    setField('testimonial_demanded', false);
    await getTemplatesShowSegments(category);
  }
  let iframesrc = '';
  const setFieldWidgetModel = async (name, value) => {
    setField(name, value);
    const data = await getTemplateShowSegment(value);
    interaction['customisation'].consent_gdpr = data.default_gdpr_consent;
    interaction['customisation'].consent_image_right = data.default_image_right_consent;
    //interaction['customisation'].limit_1_vote = data.default_limit_1_vote
    interaction.gather_information = data.default_gather_information;
    interaction.webview_version = data.default_webview_version;
    setIsInformationToGatherDisabled(data.default_gather_information);
  }

  const iframeStyle = {
    width: "360px",
    height: "600px",
    border: "0px",
    boxShadow: '1px 2px 9px #CCC'
  }
  return (
    <Wrapper>
      <Header>
        <Steps steps={CREATE_INTERACTION_STEPS} step={step} />
      </Header>
      <Container>
        {step === 0 && (
          <TypeSelectWrapper>
            {availableCategories.map((category) => (
              <InteractionType
                //active={interaction.type === type}
                onClick={() => changeInteraction(category)}
                key={category}
              >
                {
                  //INTERACTION_FRONTEND_TYPE_MAP[type].iconNew
                }
                <Text ml={'15px'} bold size={'h4'} mt={'5px'} capital>
                  {t(INTERACTION_FRONTEND_CATEGORY_MAP[category].label)}
                </Text>
              </InteractionType>
            ))}
          </TypeSelectWrapper>
        )}
        <Content>
          {step === 0 ? (
            !interaction.template_category ? (
              <Box p={'88px'} flex={1}>
                <Text mt={'50px'} bold size={'lg'} center>
                  {t(
                    'Let’s go ! Choose the new interaction we’re going to create !'
                  )}
                </Text>
                <Text
                  mt={'30px'}
                  center
                  lh={'25px'}
                  width={'300px'}
                  mx={'auto'}
                >
                  {t(
                    'Select in the left menu the type of interaction that you wish to offer to your public'
                  )}
                </Text>
              </Box>
            ) : (

              <InnerScrollWrapper>
                <Box px={'22.5px'} py={'20.5px'}>
                  <Text bold mb={'17px'} size={'h2'} capital>
                    {t(INTERACTION_FRONTEND_CATEGORY_MAP[interaction.template_category].label)}
                  </Text>
                  <>
                    <Box>
                      <Text mb={'26px'}>
                        {t(INTERACTION_FRONTEND_CATEGORY_MAP[interaction.template_category].description)}
                      </Text>
                    </Box>
                    <Text bold mb={'17px'} size={'h2'} capital>
                      {t('Choose your interaction')}
                    </Text>
                    <TableWrapper>
                      <Table>
                        <TableHeader>
                          <TableHeaderRow>
                            <TableHeaderCell>
                              <Text>{t('Description')}</Text>
                            </TableHeaderCell>
                            <TableHeaderCell>
                              <Text>{t('Type')}</Text>
                            </TableHeaderCell>
                            <TableHeaderCell >
                              <Text>{t('Selection')}</Text>
                            </TableHeaderCell>
                          </TableHeaderRow>
                        </TableHeader>
                        <TableBody>
                          {templatesShowSegments && templatesShowSegments.data &&
                            templatesShowSegments.data.map((template) => (
                              <Row key={template.id}>
                                <Cell>
                                  <Text>{t(template.description)}</Text>
                                  <Text>{t(template.under_description)}</Text>
                                </Cell>
                                <Cell>
                                  <Text>{template.customed ? t('Customed') : t('Standard')}</Text>
                                </Cell>
                                <Cell p="7px 0px">
                                  <RadioPicker
                                    name={'template_widget_model'}
                                    options={[{ value: template.widget_model }]}
                                    value={interaction.template_widget_model}
                                    inline={false}
                                    onChange={setFieldWidgetModel}
                                  />
                                </Cell>
                              </Row>
                            ))}
                        </TableBody>
                      </Table>
                    </TableWrapper>
                    <br />
                    <Box>
                      <Box>
                        <Text bold mb={'22px'} size={'h2'} capital>
                          {t('Available options')}
                        </Text>
                      </Box>
                      <Text>- {t('limit to one participation')}</Text>
                      <Text>- {t('Collect personal informations data')}</Text>
                      <Text>- {t('RGPD Consent')}</Text>
                      <Text>- {t('Image right consent')}</Text>
                    </Box>
                  </>
                </Box>
              </InnerScrollWrapper>
            )
          ) : step === 1 ? (
            <ContentWrapper>
              <>
                <Flex height={'100%'}>
                  <InnerScrollWrapper>
                    <InteractionInformation
                      interaction={interaction}
                      setField={setField}
                      customisation={customisation}
                      answerCount={answerCount}
                      addNewChoice={addNewChoice}
                      removeChoiceOnIndex={removeChoiceOnIndex}
                      isInformationToGatherDisabled={isInformationToGatherDisabled}
                      setIsInformationToGatherDisabled={setIsInformationToGatherDisabled}
                      disabledExceptions={disabledExceptions}
                      setDisabledExceptions={setDisabledExceptions}
                      templateShowSegment={templateShowSegment}
                      creation={true}
                      saveInteraction={saveInteraction}
                      isBackgroundSaved={isBackgroundSaved}
                    />
                  </InnerScrollWrapper>
                </Flex>
              </>
            </ContentWrapper>
          ) : step === 2 ? (
            <ContentWrapper>
              <InnerMaxWidth>
                <InnerScrollWrapper>
                  <InteractionAvailability
                    interaction={interaction}
                    setFrom={setFrom}
                    setField={setField}
                    setTo={setTo}
                  />
                </InnerScrollWrapper>
              </InnerMaxWidth>
            </ContentWrapper>
          ) : (
            <ContentWrapper>
              <Text>{t(
                'Congratulations, you have arrived at the last stage of setting up your interaction! You can now either: use the direct link of your interaction that you can share on all your media, or configure your interaction directly in the already existing distribution points :'
              )}</Text>
              <Flex height={'calc(100% - 61px)'}>
                <InnerScrollWrapper>
                  <InteractionInformation
                    interaction={interaction}
                    setField={setField}
                    customisation={customisation}
                    answerCount={answerCount}
                    addNewChoice={addNewChoice}
                    removeChoiceOnIndex={removeChoiceOnIndex}
                    isInformationToGatherDisabled={isInformationToGatherDisabled}
                    setIsInformationToGatherDisabled={setIsInformationToGatherDisabled}
                    disabledExceptions={disabledExceptions}
                    setDisabledExceptions={setDisabledExceptions}
                    disabled={true}
                  />
                  <InteractionAvailability
                    interaction={interaction}
                    setFrom={setFrom}
                    setField={setField}
                    setTo={setTo}
                    disabled={true}
                  />

                </InnerScrollWrapper>
              </Flex>
            </ContentWrapper>
          )}
        </Content>
      </Container>
      <Footer>
        {step === 3 ? (
          <>
            <div />
            <FlexCenter>
              <Button onClick={closeModal} mr={'25px'} theme={'light'}>
                {t('Back to the list')}
              </Button>
              <Button
                px={'52px'}
                as={Link}
                to={'/timeline'}
                onClick={closeModal}
              >
                {t('Go to distribution points')}
              </Button>
            </FlexCenter>
          </>
        ) : step === 1 && showColors ? (
          <>
            <Button onClick={resetCustomisation} theme={'light'}>
              {t('Reset default value')}
            </Button>
            <FlexCenter>
              <Button onClick={() => setShowColors(false) || saveInteraction()}>
                {t('Back to interaction settings')}
              </Button>
            </FlexCenter>
          </>
        ) : (
          <>
            {step !== 0 ? (
              <Button onClick={previousStep} theme={'light'}>
                {t('Back')}
              </Button>
            ) : (
              <div />
            )}
            <FlexCenter>
              {isSaved && (
                <Text size={'xxs'} mr={'26px'}>
                  {t('Recorded as a draft')}
                </Text>
              )}
              <Button onClick={nextStep} disabled={!isStepValid}>
                {step === 2 ? t('Activate') : t('Next')}
              </Button>
            </FlexCenter>
          </>
        )}
      </Footer>
    </Wrapper>
  );
};
