import { useEffect, useState } from 'react';
import { API_ENDPOINTS } from '../../constants/common';
import { request } from '../utils/request';
import { useHistory } from 'react-router-dom';
import { WithBackup } from '../backup/withBackup';
import { useUser } from '../../../models/users/withUser'; // TODO:NEW Remove one "../"
import { WithLoginRegister } from './withLoginRegister';
import i18n from 'i18next';

export const userStatuses = [
  { value: true, label: i18n.t('Active') },
  { value: false, label: i18n.t('Disabled') },
];

export const userLanguages = [
  { value: 'en', label: i18n.t('English'), icon: '/flags/en.png' },
  { value: 'fr', label: i18n.t('French'), icon: '/flags/fr.png' },
  { value: 'pt', label: i18n.t('Portuguese'), icon: '/flags/pt.png' },
  { value: 'pl', label: i18n.t('Polish'), icon: '/flags/pl.png' },
  { value: 'es', label: i18n.t('Spanish'), icon: '/flags/es.png' },
  { value: 'technique', label: i18n.t('Technique'), icon: '' },
];

const defaultUser = { active: true, language: 'fr' };

export const WithBEUser = ({ id: idDefault, onSave }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [user, setUser] = useState(defaultUser);
  let [id, setId] = useState();
  const { user: ownUser, setLanguage } = useUser();
  const { logout } = WithLoginRegister({});
  const history = useHistory();
  const { setBackup, backup } = WithBackup(defaultUser);

  const setField = (name, value) => setUser({ ...user, [name]: value });

  const userCreate = async () => {
    const { data } = await request({
      url: API_ENDPOINTS.usersNew,
      method: 'POST',
      body: user,
    });
    if (data.id) {
      id = data.id;
      setId(data.id);
      await userImageSave();
      history.replace(`/users/${data.id}`);
    }
  };

  const userInfoSave = async () => {
    const { data } = await request({
      url: API_ENDPOINTS.user(id),
      method: 'PUT',
      body: user,
    });
    setUser(data);
    setBackup(data);
    if (data.id === ownUser.id) {
      setLanguage(data.language);
    }
    onSave && onSave();
  };

  const userImageSave = async () => {
    if (user.image) {
      const formData = new FormData();
      formData.append('image', user.image);
      const { data } = await request({
        url: API_ENDPOINTS.userImageUpload(id),
        method: 'POST',
        formData,
      });
      setUser(data);
      setBackup(data);
    } else if (user.image === false) {
      const { data } = await request({
        url: API_ENDPOINTS.userImageUpload(id),
        method: 'DELETE',
      });
      setUser(data);
      setBackup(data);
    }
  };

  const resetPassword = async () => {
    const body = {
      email: user.login,
    };
    await request({
      url: API_ENDPOINTS.usersReset,
      method: 'POST',
      body,
    });
    if (id === ownUser.id) {
      logout(`?email=${ownUser.login}`);
    }
  };

  const saveUser = async () => {
    if (id === 'new') {
      userCreate();
    } else {
      userInfoSave();
      userImageSave();
    }
  };
  const toggleEdit = () => setIsEdit(!isEdit);

  const fetchUser = async (forceId) => {
    const { data } = await request({
      url: API_ENDPOINTS.user(forceId || id || idDefault),
    });
    setUser(data);
    setBackup(data);
  };

  const restoreBackup = () => setUser(backup);

  useEffect(() => {
    setId(idDefault);
    if (idDefault !== 'new') {
      fetchUser(idDefault);
    } else {
      setUser(defaultUser);
      setIsEdit(true);
      setBackup(defaultUser);
    }
  }, [idDefault]);

  return {
    user,
    isEdit,
    backup,
    setField,
    saveUser,
    userImageSave,
    resetPassword,
    restoreBackup,
    toggleEdit,
  };
};
