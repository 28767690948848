import { useEffect, useState } from 'react';
import { API_ENDPOINTS } from '../../constants/common';
import { request } from '../utils/request';
import { prepareInteractionSave, processInteraction } from '../../utils/utils';
import moment from 'moment';
import { WithBackup } from '../backup/withBackup';
import { MIN_CHOICE_COUNT } from '../../constants/interactions/interaction';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export const WithInteraction = ({ id: idDefault, onSave }) => {
  const [interaction, setInteraction] = useState();
  const [templateShowSegment, setTemplateShowSegment] = useState({});
  const { backup, setBackup } = WithBackup();
  const [answerCount, setAnswerCount] = useState(MIN_CHOICE_COUNT);
  const [isInformationToGatherDisabled, setIsInformationToGatherDisabled] = useState('disabled')
  const [disabledExceptions, setDisabledExceptions] = useState([]);
  const [isBackgroundSaved, setIsBackgroundSaved] = useState(true);

  let [id, setId] = useState();

  const setField = (name, value) => {
    if (name.includes('|')) {
      const [key, index] = name.split('|');
      if (!interaction[key]) {
        interaction[key] = [];
      }
      interaction[key][index] = value;
      return setInteraction({ ...interaction });
    } else if (
      name === 'start_date' &&
      interaction.end_date &&
      value >= interaction.end_date
    ) {
      const newDateEnd = value.clone().add(1, 'hour');
      setField('end_date', newDateEnd);
    } else if (
      name === 'end_date' &&
      value &&
      value <= interaction.start_date
    ) {
      const newStartDate = value.clone().subtract(1, 'hour');
      setField('start_date', newStartDate);
    }
    setInteraction((prevInteraction) => ({
      ...prevInteraction,
      [name]: value,
    }));
  };

  const interactionInfoSave = async () => {
    const body = prepareInteractionSave(interaction);
    const { data } = await request({
      url: API_ENDPOINTS.segment(id),
      method: 'PUT',
      body,
    });
    const newInteraction = {
      ...processInteraction(data),
      from: interaction.from,
      background: interaction.background,
    };
    setInteraction(newInteraction);
    setAnswerCount(newInteraction.choices?.length || MIN_CHOICE_COUNT);
    setBackup(newInteraction);
    onSave && onSave();
    // Display Toast
    toast.success('Saved !', {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const getTemplateShowSegment = async (widgetModel) => {
    if (widgetModel) {
      const data = await request({
        url: API_ENDPOINTS.getTemplateShowSegmentByWidgetModel(widgetModel),
        method: 'GET',
      })
      if (data) {
        setTemplateShowSegment(data.data)
      }
    }
  }
  const interactionBackgroundSave = async () => {
    setIsBackgroundSaved(false);
    if (interaction.background) {
      const formData = new FormData();
      if (interaction.background.type.split('/')[0] === 'video') {
        formData.append('video', interaction.background);
      } else {
        formData.append('image', interaction.background);
      }
      const { data } = await request({
        url: API_ENDPOINTS.segmentBackgroundUpload(id),
        method: 'POST',
        formData,
      });
      const newInteraction = processInteraction(data);
      setInteraction(newInteraction);
      setBackup(newInteraction);
    } else if (interaction.background === false) {
      const { data } = await request({
        url: API_ENDPOINTS.segmentBackgroundUpload(id),
        method: 'DELETE',
      });
      const newInteraction = processInteraction(data);
      newInteraction.from = interaction.from;
      setAnswerCount(newInteraction.choices?.length || MIN_CHOICE_COUNT);
      setInteraction(newInteraction);
      setBackup(newInteraction);
    }
    setIsBackgroundSaved(true);
  };

  const saveInteraction = async () => {
    await interactionInfoSave();
    await interactionBackgroundSave();
  };

  const fetchInteraction = async (forceId) => {
    if ((forceId || id || idDefault)) {
      const { data } = await request({
        url: API_ENDPOINTS.segment(forceId || id || idDefault),
      });
      const newInteraction = processInteraction(data);
      setAnswerCount(newInteraction.choices?.length || MIN_CHOICE_COUNT);
      setInteraction(newInteraction);
      setBackup(newInteraction);
      if (newInteraction?.gather_information) {
        setIsInformationToGatherDisabled('');
        setDisabledExceptions([]);
      }
      if (newInteraction?.customisation?.with_connexion) {
        setIsInformationToGatherDisabled('');
        setDisabledExceptions(["phone"]);
      }
      getTemplateShowSegment(newInteraction?.template_widget_model);
      return data;
    }
  };

  const setFrom = (name, value) => {
    if (value === 'now') {
      setField('start_date', moment());
    }
    setField(name, value);
  };

  const setTo = (name, value) => {
    if (value === 'permanent') {
      setField('end_date', null);
    } else {
      setField('end_date', moment().add(7, 'd'));
    }
    setField(name, value);
  };

  const restoreBackup = () => setInteraction(backup);

  const removeChoiceOnIndex = (removeIndex) => {
    const newChoices = interaction.choices.filter(
      (choice, index) => index !== removeIndex
    );
    setField('choices', newChoices);
    setAnswerCount(
      newChoices.length >= MIN_CHOICE_COUNT
        ? newChoices.length
        : MIN_CHOICE_COUNT
    );
  };

  const addNewChoice = () => {
    setField(`choices|${answerCount + 1}`)
    setAnswerCount(answerCount + 1)
  }

  useEffect(() => {
    setId(idDefault);
    if (parseInt(idDefault)) {
      fetchInteraction(idDefault);
    }
  }, [idDefault]);

  return {
    id,
    answerCount,
    interaction,
    setAnswerCount,
    restoreBackup,
    setField,
    setTo,
    setFrom,
    saveInteraction,
    fetchInteraction,
    interactionBackgroundSave,
    addNewChoice,
    removeChoiceOnIndex,
    isInformationToGatherDisabled,
    setIsInformationToGatherDisabled,
    disabledExceptions,
    setDisabledExceptions,
    templateShowSegment,
    isBackgroundSaved
  };
};
