import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { request } from '../utils/request';
import { API_ENDPOINTS } from '../../constants/common';
import { useUser } from '../../../models/users/withUser'; // TODO:NEW Remove one "../"
import { parse } from 'query-string';
import { useTranslation } from 'react-i18next';
import { emailRe, passwordRe, phoneRe } from '../../utils/utils';
import { useModal } from '../global/withModal';

const defaultUser = { language: 'fr' };

export const WithLoginRegister = ({ loginToken }) => {
  const [loginUser, setLoginUser] = useState({ ...defaultUser });
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [registerActive, setRegisterActive] = useState(false);
  const history = useHistory();
  const {
    fetchCurrentUser,
    setState: setUserState,
    userState,
    language,
    setLanguage,
  } = useUser();
  const { t } = useTranslation();
  const { openModal, closeModal } = useModal();

  const finishLogin = async (token) => {
    window.localStorage.setItem('access_token', token);
    if (loginToken) {
      return true;
    }
    const { inSuperAdminMode } = await fetchCurrentUser();
    setLoading(false);

    if (inSuperAdminMode) {
      history.push('/channels');
    } else {
      history.push('/interactions');
    }
  };

  const setField = (name, value) => {
    if (name === 'language') {
      setLanguage(value);
    }
    setLoginUser((prevUser) => ({ ...prevUser, [name]: value }));
  };

  const validateRegister = () => {
    if (
      !loginUser.email ||
      !loginUser.phone ||
      !loginUser.password ||
      !loginUser.first_name ||
      !loginUser.last_name
    ) {
      return setError(t('All fields are required'));
    }
    if (!phoneRe.test(loginUser.phone)) {
      return setError(t('Invalid telephone'));
    }
    if (!emailRe.test(loginUser.email)) {
      return setError(t('Invalid email'));
    }
    if (!passwordRe.test(loginUser.password)) {
      return setError(t('Invalid password'));
    }
    return true;
  };

  const register = async () => {
    if (isLoading) {
      return;
    }
    if (!validateRegister()) {
      return;
    }
    setError('');
    setLoading(true);

    const { res } = await request({
      url: API_ENDPOINTS.register,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(loginUser),
    });

    setLoading(false);
    if (res.status !== 200) {
      return setError(t('Email already taken'));
    }
    openModal('after-register');
    setLoginUser({ language });
    setRegisterActive(false);
  };

  const login = async () => {
    const { email, password } = loginUser;
    if (!loginToken && (!email || !password)) {
      return setError(t('All fields are required'));
    }
    setError('');
    setLoading(true);

    const loginData = {
      email,
      password,
      token: loginToken,
    };

    const { res, data } = await request({
      url: loginToken ? API_ENDPOINTS.authToken : API_ENDPOINTS.auth,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(loginData),
    });

    setLoading(false);
    if (res.status !== 200) {
      return setError(t('Invalid email or password'));
    }

    const { access_token } = data;
    return await finishLogin(access_token);
  };

  const logout = (extraPath = '') => {
    window.localStorage.removeItem('access_token');
    setLoading(false);
    history.push(`/login${typeof extraPath === 'string' ? extraPath : ''}`);
    setUserState({ ...userState, user: null, isLoading: false });
  };

  const resetPassword = async () => {
    const { email } = loginUser;
    if (!email) {
      return;
    }
    const body = { email };
    await request({
      url: API_ENDPOINTS.usersReset,
      method: 'POST',
      body,
    });
    closeModal();
  };

  const resendEmail = async () => {
    const { email } = loginUser;
    if (!email) {
      return;
    }
    const body = { email };
    await request({
      url: API_ENDPOINTS.usersResend,
      method: 'POST',
      body,
    });
    closeModal();
  };

  useEffect(() => {
    const queryParams = parse(history.location.search);
    if (queryParams.email) {
      setField('email', queryParams.email);
    }
    if (queryParams.token) {
      finishLogin(queryParams.token);
    }
  }, []);

  useEffect(() => {
    setField('language', language);
  }, [language]);

  return {
    error,
    openModal,
    loginUser,
    registerActive,
    // setters
    setRegisterActive,
    // methods
    login,
    register,
    closeModal,
    setField,
    resetPassword,
    logout,
    resendEmail,
  };
};
